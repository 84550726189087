import React from "react"
import * as Icon from "react-feather"
import { Link } from "gatsby"

const Features = () => {
  return (
    <div className="boxes-area">
      <div className="container">
        <div className="row justify-content-center">
          {/* <div className="col-lg-3 col-sm-6">
					<Link to="/engineering">
						<div className="single-box">
							<div className="icon">
                                <Icon.Tool />
							</div>

							<h3>
					
									 Engineering Services
			
							</h3>

							<p>We design and implement engineering systems. From CAD Models to rapidly produced, functional prototypes.</p>
						</div>
						</Link>
					</div> */}

          <div className="col-lg-3 col-sm-6">
            <Link to="/design">
              <div className="single-box bg-f78acb">
                <div className="icon">
                  <Icon.Users />
                </div>

                <h3>UI/UX Design</h3>

                <p>
                  Our human factors scientists are trained to optimize products,
                  software, and marketing campaigns.
                </p>
              </div>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6">
            <Link to="/software">
              <div className="single-box bg-c679e3">
                <div className="icon">
                  <Icon.Code />
                </div>

                <h3>Full-Stack Software Development</h3>

                <p>
                  We develop intuitive, data-driven web, mobile, and AR/VR
                  applications.
                </p>
              </div>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6">
            <Link to="/analytics">
              <div className="single-box bg-eb6b3d">
                <div className="icon">
                  <Icon.BarChart2 />
                </div>

                <h3>Machine Learning & Big Data Analytics</h3>

                <p>
                  Our data scientists stand at the ready to implement
                  predictive, A.I algorithms to meet your product or business
                  needs.
                </p>
              </div>
            </Link>
          </div>
        </div>

        <div style={{ marginTop: 30 }} className="row justify-content-center">
          <div className="col-lg-3 col-sm-6">
            <Link to="/manufacturing">
              <div className="single-box bg-eb6b3d">
                <div className="icon">
                  <Icon.Package />
                </div>

                <h3>Manufacturing Services</h3>

                <p>
                  We leverage our global relationships to quickly establish
                  manufacturing supply chains to produce your product at a low
                  cost.
                </p>
              </div>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6">
            <Link to="/simulation">
              <div className="single-box bg-c679e3">
                <div className="icon">
                  <Icon.Cpu />
                </div>

                <h3>Simulation & Modeling</h3>

                <p>
                  We leverage system dynamics, agent-based, and physics models
                  to simulate, evaluate, and inform the design of complex
                  systems.
                </p>
              </div>
            </Link>
          </div>

          {/* <div className="col-lg-3 col-sm-6">
				<Link to="/research">
				<div className="single-box bg-f78acb">
					<div className="icon">
						<Icon.Search />
					</div>

					<h3>
						
							Research & Patenting
		
					</h3>

					<p>Our law team and PhD researchers are able to ensure that your solution is research-informed and that your IP is protected.</p>
				</div>
				</Link>
			</div> */}

          <div className="col-lg-3 col-sm-6">
            <Link to="/marketing">
              <div className="single-box">
                <div className="icon">
                  <Icon.Rss />
                </div>

                <h3>Marketing & Communications</h3>

                <p>
                  Our team can meet your content creation need and establish
                  targeted advertising campaigns across the web for your
                  products.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
