import React from "react"
import * as Icon from "react-feather"

import Team1 from "../../assets/images/team-image/team1.jpg"
import Team2 from "../../assets/images/team-image/team2.jpg"
import MarkNaufel from "../../assets/images/team-members/marknaufel.png"
import LaurenNaufel from "../../assets/images/team-members/laurennaufel.png"
import NaufelNaufel from "../../assets/images/team-members/naufelnaufel.png"
import TylerSmith from "../../assets/images/team-members/tylersmith.png"
import Micki from "../../assets/images/team-members/micki.png"
import Slaughter from "../../assets/images/team-members/slaughter.png"
import Erik from "../../assets/images/team-members/erik.png"
import Ryan from "../../assets/images/team-members/ryan.png"
import Dave from "../../assets/images/team-members/nikdave.png"

import Team4 from "../../assets/images/team-image/team4.jpg"
import Team5 from "../../assets/images/team-image/team5.jpg"

import Loadable from "@loadable/component"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  items: 5,
  loop: true,
  nav: false,
  dots: true,
  margin: 30,
  autoplay: false,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    900: {
      items: 3,
    },
    1200: {
      items: 4,
    },
    1500: {
      items: 5,
    },
  },
}

const Team = () => {
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <div className="team-area ptb-80 bg-f9f6f6">
      <div className="container">
        <div className="section-title">
          <h2>Our Leadership Team</h2>

          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
        </div>
      </div>

      {display ? (
        <OwlCarousel
          className="team-slider owl-carousel owl-theme owl-theme-style"
          {...options}
        >
          <div className="single-team">
            <div className="team-image">
              <img src={LaurenNaufel} alt="image" />
            </div>

            <div className="team-content">
              <div className="team-info">
                <h3>Lauren Naufel</h3>
                <span>VP of Operations</span>
              </div>

              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/in/laurenmcburnett"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Linkedin />
                  </a>
                </li>
              </ul>

              <p>
                {" "}
                <ul>
                  <li>PhD, Systems Engineering</li>
                  <li>Masters, Civil Engineering</li>
                  <li>BS, Civil Engineering</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="single-team">
            <div className="team-image">
              <img src={TylerSmith} alt="image" />
            </div>

            <div className="team-content">
              <div className="team-info">
                <h3>Tyler Smith</h3>
                <span>VP of Engineering</span>
              </div>

              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/in/tyler-smith-d"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Linkedin />
                  </a>
                </li>
              </ul>

              <p>
                <ul>
                  <li>PhD Candidate, Mech. Engineering</li>
                  <li>Masters, Mechanical Engineering</li>
                  <li>BS, Mechanical Engineering</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="single-team">
            <div className="team-image">
              <img src={MarkNaufel} alt="image" />
            </div>

            <div className="team-content">
              <div className="team-info">
                <h3>Mark Naufel</h3>
                <span>President</span>
              </div>

              <ul>
                {/* <li>
                                <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                    <Icon.Facebook />
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/" target="_blank" rel="noreferrer">
                                    <Icon.Twitter />
                                </a>
                            </li> */}
                <li>
                  <a
                    href="https://www.linkedin.com/in/mark-naufel-09927643/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Linkedin />
                  </a>
                </li>
                {/* <li>
                                <a href="https://gitlab.com/" target="_blank" rel="noreferrer">
                                    <Icon.Gitlab />
                                </a>
                            </li> */}
              </ul>

              <p>
                <ul>
                  <li>PhD, Human Systems Engineering</li>
                  <li>Masters, Systems Engineering</li>
                  <li>Masters, Business Data Analytics</li>
                  <li>BS, Finance</li>
                </ul>{" "}
              </p>
            </div>
          </div>

          <div className="single-team">
            <div className="team-image">
              <img src={Micki} alt="image" />
            </div>

            <div className="team-content">
              <div className="team-info">
                <h3>Micki Calo</h3>
                <span>VP of Design</span>
              </div>

              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/in/michelina-calo/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Linkedin />
                  </a>
                </li>
              </ul>

              <p>
                <ul>
                  <li>B.S. Industrial Design</li>
                  <li>Digital Marketing</li>
                  <li>Graphic/Interface Design</li>
                  <li>User Experience Design</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="single-team">
            <div className="team-image">
              <img src={NaufelNaufel} alt="image" />
            </div>

            <div className="team-content">
              <div className="team-info">
                <h3>Naufel Naufel</h3>
                <span>VP of Manufacturing</span>
              </div>

              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/in/naufel-naufel-05159516/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Linkedin />
                  </a>
                </li>
              </ul>

              <p>
                {" "}
                <ul>
                  <li>41 Years of Industry Experience</li>
                  <li>Global Manufacturing Relationships</li>
                  <li>Principal Value Engineer</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="single-team">
            <div className="team-image">
              <img src={Dave} alt="image" />
            </div>

            <div className="team-content">
              <div className="team-info">
                <h3>Nikhil Dave</h3>
                <span>VP of Research</span>
              </div>

              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/in/nikhil-dave/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Linkedin />
                  </a>
                </li>
              </ul>

              <p>
                {" "}
                <ul>
                  <li>Regent at Arizona Board of Regents</li>
                  <li>Neuroscience</li>
                  <li>Science & Technology Policy</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="single-team">
            <div className="team-image">
              <img src={Ryan} alt="image" />
            </div>

            <div className="team-content">
              <div className="team-info">
                <h3>Ryan Theisen</h3>
                <span>VP of Analytics</span>
              </div>

              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/in/ryan-theisen-279b249b/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Linkedin />
                  </a>
                </li>
              </ul>

              <p>
                {" "}
                <ul>
                  <li>PhD Candidate, UC Berkeley M.L</li>
                  <li>Masters, Mathematics</li>
                  <li>BS, Mathematics & Economics</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="single-team">
            <div className="team-image">
              <img src={Slaughter} alt="image" />
            </div>

            <div className="team-content">
              <div className="team-info">
                <h3>Alex Slaughter</h3>
                <span>VP of Software</span>
              </div>

              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/in/alexslaughter/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Linkedin />
                  </a>
                </li>
              </ul>

              <p>
                {" "}
                <ul>
                  <li>Tech Entrepreneur</li>
                  <li>Masters, Software Engineering</li>
                  <li>BS, Electrical & Computer Engineering</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="single-team">
            <div className="team-image">
              <img src={Erik} alt="image" />
            </div>

            <div className="team-content">
              <div className="team-info">
                <h3>Erik DeMar</h3>
                <span>VP of Sales</span>
              </div>

              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/in/erik-demar-8b3342255/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Linkedin />
                  </a>
                </li>
              </ul>

              <p>
                {" "}
                <ul>
                  <li>Entrepreneur</li>
                  <br></br>
                  <li>Financial Analyst</li>
                  <br></br>
                  <li>BS, Finance</li>
                </ul>
              </p>
            </div>
          </div>
        </OwlCarousel>
      ) : (
        ""
      )}
    </div>
  )
}

export default Team
